// import { UserRole } from '../helpers/authHelper';

/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English', direction: 'ltr' },
  { id: 'ar', name: 'Arabic', direction: 'rtl' },
];
// const firebaseConfigQA = {
//   apiKey: 'AIzaSyCM8p8y22CgYwMdjiKLFfl3lWQ3cf_N1H8',
//   authDomain: 'supply-qa.firebaseapp.com',
//   projectId: 'supply-qa',
//   storageBucket: 'supply-qa.appspot.com',
//   messagingSenderId: '428145837378',
//   appId: '1:428145837378:web:eeeccba92b0495eec2f4db'
// };
// const firebaseConfigQA = {
//   apiKey: 'AIzaSyCADXDR33XJC3gCS3iaFLWytXo2m4aB_OM',
//   authDomain: 'searchvm-dev.firebaseapp.com',
//   databaseURL: 'https://searchvm-dev.firebaseio.com',
//   projectId: 'searchvm-dev',
//   storageBucket: 'searchvm-dev.appspot.com',
//   messagingSenderId: '679987494918',
//   appId: '1:679987494918:web:79f0c7ed8b4afc2f368387',
// };
// const firebaseConfigDev = {
//   apiKey: 'AIzaSyCADXDR33XJC3gCS3iaFLWytXo2m4aB_OM',
//   authDomain: 'searchvm-dev.firebaseapp.com',
//   databaseURL: 'https://searchvm-dev.firebaseio.com',
//   projectId: 'searchvm-dev',
//   storageBucket: 'searchvm-dev.appspot.com',
//   messagingSenderId: '679987494918',
//   appId: '1:679987494918:web:79f0c7ed8b4afc2f368387',
// };

// export const firebaseConfig =
//   process.env.NODE_ENV === 'development' ? firebaseConfigDev : firebaseConfigQA;
export const firebaseConfig = JSON.parse(process.env.STYLI_APP_FIREBASE_INIT);
export const currentUser = {
  id: 1,
  title: 'Sarah Kortney',
  img: '/assets/img/profiles/l-1.jpg',
  date: 'Last seen today 15:24',
  role: 0,
};

export const adminRoot = '/app';
export const buyUrl = 'https://1.envato.market/k4z0';
export const searchPath = `${adminRoot}/pages/miscellaneous/search`;
export const servicePath = process.env.MULIN_API_HOST; // 'https://mulin-dev.stylishop.store';
// export const servicePath = 'https://vm-dev.stylishop.store';

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = false;
export const defaultColor = 'light.purplemonster';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];
